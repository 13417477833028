import React, {useState} from 'react';
import MainContainer from './container/MainContainer'
import {Router} from "react-router";
import {createBrowserHistory} from 'history'
import {IconButton, Link as MLink, Snackbar, withStyles} from "@material-ui/core";
import {Link} from "react-router-dom";
import {Close as CloseIcon} from "@material-ui/icons";

const history = createBrowserHistory();

const style = (theme) => ({
    close: {
        padding: theme.spacing(0.5),
    },
});

export default withStyles(style)(function App({classes}) {

    const [open, setOpen] = useState(true);

  return (
      <Router history={history}>
        <MainContainer />
          <div id="snackbar">
              <Snackbar
                  anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                  }}
                  open={open}
                  onClose={() => setOpen(false)}
                  ContentProps={{
                      'aria-describedby': 'message-id',
                  }}
                  message={
                      <span id="message-id">
                            There are no cookies left for you :(<br/>
                            <MLink component={Link} to="/impressum">Impressum</MLink>
                        </span>
                  }
                  action={[
                      <IconButton
                          key="close"
                          aria-label="close"
                          color="inherit"
                          className={classes.close}
                          onClick={() => setOpen(false)}
                      >
                          <CloseIcon />
                      </IconButton>,
                  ]}
              />
          </div>
      </Router>
  );
})
