import {Route, Switch} from "react-router";
import About from "./views/About";
import Resume from "./views/Resume";
import Work from "./views/Work";
import Contact from "./views/Contact";
import Impressum from "./views/Impressum";
import React from "react";
import NotFound from "./views/NotFound";

export default function Router({location}) {

    return (
        <Switch location={location}>
            <Route path="/" component={About} exact/>
            <Route path="/resume" component={Resume}/>
            <Route path="/work" component={Work}/>
            <Route path="/contact" component={Contact}/>
            <Route path="/impressum" component={Impressum}/>
            <Route component={NotFound}/>
        </Switch>
    )
}