import React from 'react';
import { hydrate, render } from "react-dom";
import App from './App';
import * as serviceWorker from './serviceWorker';
import { create } from 'jss';
import preset from 'jss-preset-default';
import JssProvider from 'react-jss/lib/JssProvider';

import '@fortawesome/fontawesome-free/css/all.css'
import 'react-perfect-scrollbar/dist/css/styles.css'

import './VerticalTimeline.css'
import './VerticalTimelineElement.css'

import {createMuiTheme, MuiThemeProvider} from "@material-ui/core";
import lightBlue from '@material-ui/core/colors/lightBlue';
import amber from '@material-ui/core/colors/amber';

const theme = createMuiTheme({
    palette: {
        primary: lightBlue,
        secondary: amber,
    }
});

const createGenerateClassName = () => {
    let counter = 0;
    return (rule) => `c${Math.random().toString(36).substring(2, 4) + Math.random().toString(36).substring(2, 4)}-${rule.key}-${counter++}`
};

const jss = create(preset());
jss.options.createGenerateClassName = createGenerateClassName;

const rootElement = document.getElementById("root");
const rootComponent = (
    <JssProvider jss={jss}>
        <MuiThemeProvider theme={theme}>
            <App />
        </MuiThemeProvider>
    </JssProvider>
);
if (rootElement.hasChildNodes()) {
    hydrate(rootComponent, rootElement);
} else {
    render(rootComponent, rootElement);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
