import React from 'react'
import {Divider, Typography, withStyles} from "@material-ui/core";
import {Work as WorkIcon, School as SchoolIcon, Star as StarIcon} from "@material-ui/icons";
import VerticalTimeline from '../components/VerticalTimeline'
import VerticalTimelineElement from '../components/VerticalTimelineElement'
import colorBlue from '@material-ui/core/colors/lightBlue'
import colorAmber from '@material-ui/core/colors/amber'
import colorGreen from '@material-ui/core/colors/green'

const styles = () => ({
    content: {
        margin: 15
    },
    timeline: {
        margin: 15,
        marginTop: 0
    },
    description: {
        marginTop: '0.5em !important'
    }
});

export default withStyles(styles)(function Resume(props) {

    const {classes} = props;

    return (
        <div>
            <div className={classes.content}>
                <Typography variant="h5">Resume</Typography>
            </div>
            <Divider component="div"/>
            <div className={classes.timeline}>
                <VerticalTimeline layout="1-column">
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        date="September 2019 - February 2020"
                        iconStyle={{ background: colorBlue[500], color: '#fff' }}
                        icon={<WorkIcon />}
                    >
                        <Typography variant="h5">Merck - Frontend Development</Typography>
                        <Typography variant="h6">Darmstadt, Germany</Typography>
                        <Typography variant="body1" className={classes.description}>
                            Working Student for React Frontend and Node.js Backend Development
                        </Typography>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--education"
                        date="2019 - present"
                        iconStyle={{ background: colorAmber[500], color: '#fff' }}
                        icon={<SchoolIcon />}
                    >
                        <Typography variant="h5">TU Darmstadt - B.Sc. Computer Science</Typography>
                        <Typography variant="h6">Darmstadt, Germany</Typography>
                        <Typography variant="body1" className={classes.description}>
                            Bachelor of Science in Computer Science (second course of study)
                        </Typography>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        date="2018 - present"
                        iconStyle={{ background: colorBlue[500], color: '#fff' }}
                        icon={<WorkIcon />}
                    >
                        <Typography variant="h5">Extrigate - CEO</Typography>
                        <Typography variant="h6">Darmstadt, Germany</Typography>
                        <Typography variant="body1" className={classes.description}>
                            Cloud Operating System
                        </Typography>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--education"
                        date="2018 - present"
                        iconStyle={{ background: colorAmber[500], color: '#fff' }}
                        icon={<SchoolIcon />}
                    >
                        <Typography variant="h5">TU Darmstadt - B.Sc. Physics</Typography>
                        <Typography variant="h6">Darmstadt, Germany</Typography>
                        <Typography variant="body1" className={classes.description}>
                            Bachelor of Science in Physics
                        </Typography>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        date="August 2017 - October 2017"
                        iconStyle={{ background: colorBlue[500], color: '#fff' }}
                        icon={<WorkIcon />}
                    >
                        <Typography variant="h5">Omada - Software Tester</Typography>
                        <Typography variant="h6">Darmstadt, Germany</Typography>
                        <Typography variant="body1" className={classes.description}>
                            Tester for Identity Management Software
                        </Typography>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        date="August 2016"
                        iconStyle={{ background: colorBlue[500], color: '#fff' }}
                        icon={<WorkIcon />}
                    >
                        <Typography variant="h5">CST - Internship</Typography>
                        <Typography variant="h6">Darmstadt, Germany</Typography>
                        <Typography variant="body1" className={classes.description}>
                            IT Management
                        </Typography>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--education"
                        date="2015 - 2017"
                        iconStyle={{ background: colorAmber[500], color: '#fff' }}
                        icon={<SchoolIcon />}
                    >
                        <Typography variant="h5">Eleonorenschule - High School</Typography>
                        <Typography variant="h6">Darmstadt, Germany</Typography>
                        <Typography variant="body1" className={classes.description}>
                            A Levels (Abitur)
                        </Typography>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        date="March 2015"
                        iconStyle={{ background: colorBlue[500], color: '#fff' }}
                        icon={<WorkIcon />}
                    >
                        <Typography variant="h5">CST - Internship</Typography>
                        <Typography variant="h6">Darmstadt, Germany</Typography>
                        <Typography variant="body1" className={classes.description}>
                            IT Management
                        </Typography>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        iconStyle={{ background: colorGreen[500], color: '#fff' }}
                        icon={<StarIcon />}
                    />
                </VerticalTimeline>
            </div>
        </div>
    )
})