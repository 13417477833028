import React from 'react'
import {withStyles} from "@material-ui/core";
import classNames from 'classnames'

const style = theme => ({
    item: {
        display: 'inline-block',
        width: '25%'
    },
    container: {
        display: 'flex',
        justifyContent: 'center'
    },
    icon: {
        color: theme.palette.grey["800"]
    }
});

export default withStyles(style)(function SocialLinks(props) {

    const {classes} = props;

    return (
        <React.Fragment>
                <div className={classes.item}>
                    <div className={classes.container}>
                        <a href="https://www.youtube.com/channel/UC2WYhLSL9jItW-gf6buOKJA" target="_blank" rel="noopener noreferrer">
                            <i className={classNames("fab fa-youtube fa-lg", classes.icon)}/>
                        </a>
                    </div>
                </div>
                <div className={classes.item}>
                    <div className={classes.container}>
                        <a href="https://www.linkedin.com/in/marcel-mittenb%C3%BChler-9a25b8175/" target="_blank" rel="noopener noreferrer">
                            <i className={classNames("fab fa-linkedin fa-lg", classes.icon)}/>
                        </a>
                    </div>
                </div>
                <div className={classes.item}>
                    <div className={classes.container}>
                        <a href="https://github.com/MMittenbuehler" target="_blank" rel="noopener noreferrer">
                            <i className={classNames("fab fa-github fa-lg", classes.icon)}/>
                        </a>
                    </div>
                </div>
                <div className={classes.item}>
                    <div className={classes.container}>
                        <a href="https://twitter.com/MMittenbuehler" target="_blank" rel="noopener noreferrer">
                            <i className={classNames("fab fa-twitter fa-lg", classes.icon)}/>
                        </a>
                    </div>
                </div>
        </React.Fragment>
    );
})