import React from 'react';
import {Hidden, Paper, withStyles} from "@material-ui/core";
import PersonOverview from "../components/PersonOverview";
import PerfectScrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css'
import {TransitionGroup, CSSTransition} from "react-transition-group"
import {Route} from 'react-router-dom'
import '../transitions.css'
import Router from '../router'

const style = (theme) => ({
    smHeader: {
        position: 'absolute',
        top: 'calc((10vw - 15px) * 0.382 / 0.618)',
        left: 'calc(10vw - 15px)',
        width: 'calc(80vw + 30px)',
        height: 'calc(30px + 2 * 0.382 * 12vh + 0.618 * 12vh + 1.33 * 1.5rem + 1.75em + 0.933em + 8.5vh + 10vh)',
        zIndex: '20'
    },
    smContent: {
        position: 'absolute',
        top: 'calc((10vw - 15px) * 0.382 / 0.618 - 15px + (30px + 2 * 0.382 * 12vh + 0.618 * 12vh + 1.33 * 1.5rem + 1.75em + 0.933em + 8.5vh + 10vh))',
        left: '10vw',
        width: '80vw',
        zIndex: '10',
        marginBottom: 'calc((10vw - 15px) * 0.382 / 0.618)'
    },
    mdHeader: {
        position: 'absolute',
        top: '10vh',
        left: '10vw',
        width: 'calc(80vw * 0.38)',
        height: '80vh',
        zIndex: '20'
    },
    mdContent: {
        position: 'absolute',
        top: 'calc(10vh + 15px)',
        left: 'calc(10vw + 80vw * 0.38 - 15px)',
        width: 'calc(80vw * 0.61)',
        height: 'calc(80vh - 30px)',
        zIndex: '10'
    },
    lgHeader: {
        position: 'absolute',
        top: 'calc(38.2vh / 2)',
        left: '19vw',
        width: 'calc(61.8vw * 0.382)',
        height: '61.8vh',
        zIndex: '20'
    },
    lgContent: {
        position: 'absolute',
        top: 'calc(38.2vh / 2 + 15px)',
        left: 'calc(38.2vw / 2 + 61.8vw * 0.382 - 15px)',
        width: 'calc(61.8vw * 0.618)',
        height: 'calc(61.8vh - 30px)',
        zIndex: '10'
    },
    close: {
        padding: theme.spacing(0.5),
    },
});

class MainContainer extends React.Component {

    state = {
        open: true
    };

    handleClose = () => {
        this.setState({open: false})
    };

    render() {

        const {classes} = this.props;

        return (
            <div>
                <Hidden mdUp>
                    <Paper id="header"
                           elevation={10}
                           className={classes.smHeader}>
                        <div style={{margin: '15px'}}>
                            <PersonOverview/>
                        </div>
                    </Paper>
                    <Route path="/" render={(rp) => (
                        <TransitionGroup>
                            <CSSTransition
                                key={rp.location.key}
                                timeout={{ enter: 600, exit: 600 }}
                                classNames={'slide-vertical'}
                            >
                                <Paper id="content"
                                       elevation={3}
                                       className={classes.smContent}>
                                    <div style={{marginTop: '30px'}}>
                                        <Router location={rp.location} />
                                    </div>
                                </Paper>
                            </CSSTransition>
                        </TransitionGroup>
                    )}/>
                </Hidden>
                <Hidden smDown lgUp>
                    <Paper id="header"
                           elevation={10}
                           className={classes.mdHeader}>
                        <div style={{margin: '15px'}}>
                            <PersonOverview/>
                        </div>
                    </Paper>
                    <Route path="/" render={(rp) => (
                        <TransitionGroup>
                            <CSSTransition
                                key={rp.location.key}
                                timeout={{ enter: 300, exit: 300 }}
                                classNames={'slide-horizontal'}
                            >
                                <Paper id="content"
                                       elevation={3}
                                       className={classes.mdContent}>
                                    <div style={{marginLeft: '15px', height: '100%'}}>
                                        <PerfectScrollbar options={{suppressScrollX: true}}>
                                            <Router location={rp.location} />
                                        </PerfectScrollbar>
                                    </div>
                                </Paper>
                            </CSSTransition>
                        </TransitionGroup>
                    )}/>
                </Hidden>
                <Hidden mdDown>
                    <Paper id="header"
                           elevation={10}
                           className={classes.lgHeader}>
                        <div style={{margin: '15px'}}>
                            <PersonOverview/>
                        </div>
                    </Paper>
                    <Route path="/" render={(rp) => (
                        <TransitionGroup>
                            <CSSTransition
                                key={rp.location.key}
                                timeout={{ enter: 300, exit: 300 }}
                                classNames={'slide-horizontal'}
                            >
                                <Paper id="content"
                                       elevation={3}
                                       className={classes.lgContent}>
                                    <div style={{marginLeft: '15px', height: '100%'}}>
                                        <PerfectScrollbar options={{suppressScrollX: true}}>
                                            <Router location={rp.location} />
                                        </PerfectScrollbar>
                                    </div>
                                </Paper>
                            </CSSTransition>
                        </TransitionGroup>
                    )}/>
                </Hidden>
            </div>
        );
    }
}

export default withStyles(style)(MainContainer)