import React from 'react'
import {Typography, Divider, withStyles, Link} from "@material-ui/core";

const styles = () => ({
    content: {
        margin: 15
    }
});

export default withStyles(styles)(function Impressum(props) {

    const {classes} = props;

    return (
        <div>
            <div className={classes.content}>
                <Typography variant="h5">Impressum</Typography>
            </div>
            <Divider component="div"/>
            <div className={classes.content}>
                <Typography variant="body1" style={{marginTop: '20px'}}>
                    Marcel Mittenb&uuml;hler<br/>
                    Barbaraweg 1<br/>
                    64347 Griesheim<br/>
                    <br/>
                    E-Mail: <Link component="a" href="mailto:marcel@mittenbuehler.com">marcel@mittenbuehler.com</Link><br/>
                    <br/>
                    Responsible for the content: Marcel Mittenb&uuml;hler
                    <br/><br/>
                    This website doesn't use cookies nor track it's visitors.<br/>
                    For technical reasons we store the following log data for up to 28 days:
                    <ul>
                        <li>Remote IP</li>
                        <li>Remote Identity</li>
                        <li>Remote User</li>
                        <li>Time took by the Request</li>
                        <li>First Line of Request</li>
                        <li>Request Status</li>
                        <li>Sent Data (Volume)</li>
                        <li>Referrer</li>
                        <li>Useragent</li>
                    </ul>
                </Typography>
            </div>
        </div>
    )
})