import React from 'react'
import {Divider, Typography, Link as MLink, withStyles} from "@material-ui/core";
import {Link} from "react-router-dom";

const styles = () => ({
    content: {
        margin: 15
    }
});

export default withStyles(styles)(function Contact(props) {

    const {classes} = props;

    return (
        <div>
            <div className={classes.content}>
                <Typography variant="h5">Contact</Typography>
            </div>
            <Divider component="hr"/>
            <div className={classes.content}>
                <Typography variant="body1" style={{marginTop: '20px'}}>
                    The answer is 42!<br/><br/>
                    If this is not the answer to your question, please drop me a mail
                    at <MLink component="a" href="mailto:marcel@mittenbuehler.com">marcel@mittenbuehler.com</MLink>.<br/><br/>
                    If you are looking for the <MLink component={Link} to="/impressum">Impressum</MLink>, there you go :)
                </Typography>
            </div>
            <Divider component="hr"/>
            <div className={classes.content}>
                <Typography variant="body1" style={{marginTop: '20px'}}>
                    switch( visitor.getConcern() )&nbsp;{'{'}<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;case CONTACT:<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;return "<MLink component="a" href="mailto:marcel@mittenbuehler.com">marcel@mittenbuehler.com</MLink>";<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;case IMPRESSUM:<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;return "<MLink component={Link} to="/impressum">Impressum</MLink>";<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;default:<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;return "42";<br/>
                    }<br/>
                </Typography>
            </div>
        </div>
    )
})