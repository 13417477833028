import React from 'react'
import clsx from 'clsx';
import {Divider, Typography, withStyles, Card, CardContent, CardMedia, CardActions, IconButton, Collapse, Link} from "@material-ui/core";
import {ExpandMore as ExpandMoreIcon} from "@material-ui/icons";

const styles = theme => ({
    content: {
        margin: 15
    },
    media: {
        height: 0,
        paddingTop: '25%',
        backgroundSize: 'contain',
        margin: '0 2em'
    },
    subtitle: {
        padding: '0 1em'
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        marginRight: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
});

export default withStyles(styles)(function Work(props) {

    const [expandedExtrigate, setExpandedExtrigate] = React.useState(false);
    const [expandedAlgoVisio, setExpandedAlgoVisio] = React.useState(false);
    const {classes} = props;

    function handleExpandExtrigateClick() {
        setExpandedExtrigate(!expandedExtrigate);
    }

    function handleExpandAlgoVisioClick() {
        setExpandedAlgoVisio(!expandedAlgoVisio);
    }

    return (
        <div>
            <div className={classes.content}>
                <Typography variant="h5">Recent Projects</Typography>
            </div>
            <Divider component="div"/>
            <div className={classes.content}>

                <Card style={{marginTop: 16}}>
                    <CardMedia
                        className={classes.media}
                        image="/img/works/algovisio.png"
                        title="AlgoVisio"
                    />
                    <CardContent className={classes.subtitle}>
                        <Typography variant="body1" color="textSecondary" component="p">
                            AlgoVisio - Explainatory Visualizations of Algorithms and Data Structures
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <IconButton
                            className={clsx(classes.expand, {
                                [classes.expandOpen]: expandedAlgoVisio,
                            })}
                            onClick={handleExpandAlgoVisioClick}
                            aria-expanded={expandedAlgoVisio}
                            aria-label="show more"
                        >
                            <ExpandMoreIcon />
                        </IconButton>
                    </CardActions>
                    <Collapse in={expandedAlgoVisio} timeout="auto" unmountOnExit>
                        <CardContent>
                            <Typography paragraph>
                                Website: <Link component="a" href="https://algovisio.net" target="_blank">https://algovisio.net</Link>
                            </Typography>
                        </CardContent>
                    </Collapse>
                </Card>

                <Card style={{marginTop: 16}}>
                    <CardMedia
                        className={classes.media}
                        image="/img/works/extrigate.png"
                        title="Extrigate"
                    />
                    <CardContent className={classes.subtitle}>
                        <Typography variant="body1" color="textSecondary" component="p">
                            Extrigate - The Cloud Operating System
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <IconButton
                            className={clsx(classes.expand, {
                                [classes.expandOpen]: expandedExtrigate,
                            })}
                            onClick={handleExpandExtrigateClick}
                            aria-expanded={expandedExtrigate}
                            aria-label="show more"
                        >
                            <ExpandMoreIcon />
                        </IconButton>
                    </CardActions>
                    <Collapse in={expandedExtrigate} timeout="auto" unmountOnExit>
                        <CardContent>
                            <Typography paragraph>
                                Website: <Link component="a" href="https://extrigate.com" target="_blank">https://extrigate.com</Link>
                            </Typography>
                        </CardContent>
                    </Collapse>
                </Card>

            </div>
        </div>
    )
})