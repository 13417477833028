import React from "react"
import Typist from "react-typist"
import {Typography} from "@material-ui/core";

class TitleTypist extends React.Component {

    state = {
        typing: true
    };

    componentWillMount() {
        this.timeouts = [];
    }

    componentWillUnmount() {
        this.timeouts.forEach(window.clearTimeout);
    }


    done = () => {

        this.setState({ typing: false }, () => {
            this.timeouts.push(
                setTimeout(() => this.setState({ typing: true }), 100)
            )
        });
    };

    render() {

        return (
            <Typography variant="subtitle1" color="primary">
                {this.state.typing ? (
                    <Typist onTypingDone={() => this.setState({ typing: false })}>
                        {['Software Engineer', 'Physicist'].map(word => ([
                            <span>{word}</span>,
                            <Typist.Backspace count={word.length} delay={3000} />,
                        ]))}
                    </Typist>
                ) : (
                    <Typist key="placeholder" onTypingDone={() => this.setState({ typing: true })}/>
                )}
            </Typography>
        );
    }
}

export default TitleTypist