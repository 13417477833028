import React from 'react'
import {Typography, Divider, withStyles} from "@material-ui/core";

const styles = () => ({
    content: {
        margin: 15
    }
});

export default withStyles(styles)(function About(props) {

    const {classes} = props;

    return (
        <div>
            <div className={classes.content}>
                <Typography variant="h5">About Me</Typography>
            </div>
            <Divider component="div"/>
            <div className={classes.content}>
                <Typography variant="body1" style={{marginTop: '20px'}}>
                    Hello World!<br/>
                    I am a Software Engineer and Physicist, currently based in Darmstadt, Germany.<br/>

                    <br/>

                    This website is still work in progress...

                </Typography>
            </div>
        </div>
    )
})