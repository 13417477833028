import React from 'react'
import {Typography, Divider, withStyles, Link as MLink} from "@material-ui/core";
import {Link} from 'react-router-dom'

const styles = () => ({
    content: {
        margin: 15
    }
});

export default withStyles(styles)(function NotFound(props) {

    const {classes} = props;

    return (
        <div>
            <div className={classes.content}>
                <Typography variant="h5">404 - Not Found</Typography>
            </div>
            <Divider component="div"/>
            <div className={classes.content}>
                <Typography variant="body1" style={{marginTop: '20px'}}>
                    The page you requested could not be found.<br/>
                    <MLink component={Link} to="/">Home</MLink>
                </Typography>
            </div>
        </div>
    )
})