import React from 'react'
import {Button, withStyles} from "@material-ui/core";
import {NavLink} from "react-router-dom";

const style = theme => ({
    activeLink: {
        color: theme.palette.primary.main
    },
    navElement: {
        width: '25%',
        display: 'inline-block'
    },
    navElementCenter: {
        display: 'flex',
        justifyContent: 'center'
    }
});

const MyNavLink = React.forwardRef((props, ref) => <div ref={ref}><NavLink {...props}/></div>);

export default withStyles(style)(function NavBar(props) {

    const {classes} = props;

    return (
        <React.Fragment>
            <div className={classes.navElement}>
                <div className={classes.navElementCenter}>
                    <Button component={MyNavLink} to="/" href="/" exact activeClassName={classes.activeLink}>
                        About
                    </Button>
                </div>
            </div>
            <div className={classes.navElement}>
                <div className={classes.navElementCenter}>
                    <Button component={MyNavLink} to="/resume" href="/resume" exact activeClassName={classes.activeLink}>
                        Resume
                    </Button>
                </div>
            </div>
            <div className={classes.navElement}>
                <div className={classes.navElementCenter}>
                    <Button component={MyNavLink} to="/work" href="/work" exact activeClassName={classes.activeLink}>
                        Work
                    </Button>
                </div>
            </div>
            <div className={classes.navElement}>
                <div className={classes.navElementCenter}>
                    <Button component={MyNavLink} to="/contact" href="/contact" exact activeClassName={classes.activeLink}>
                        Contact
                    </Button>
                </div>
            </div>
        </React.Fragment>
    );
})