import React from 'react'
import NavBar from "./NavBar";
import {Avatar, Typography, Grid, withStyles} from "@material-ui/core";
import SocialLinks from "./SocialLinks";
import TitleTypist from "./TitleTypist";

const style = theme => ({
    avatar: {
        margin: 'calc(0.382 * 12vh) 10px calc(0.382 * 12vh) 10px',
        width: 'calc(0.612 * 12vh)',
        height: '7.5vh',
        backgroundColor: theme.palette.primary.main
    },
    social: {
        width: '80%',
        [theme.breakpoints.down('md')]: {
            marginTop: '10vh',
        },
        [theme.breakpoints.up('md')]: {
            marginTop: 'calc(61.8vh - 30px - 2 * 0.382 * 12vh - 0.618 * 12vh - 1.33 * 1.5rem - 1.75em - 0.933em - 8.5vh)',
        },
        [theme.breakpoints.only('md')]: {
            marginTop: 'calc(80vh - 30px - 2 * 0.382 * 12vh - 0.618 * 12vh - 1.33 * 1.5rem - 1.75em - 0.933em - 10vh)'
        },
    }
});

class PersonOverview extends React.Component {

    state = {
        typing: true
    };

    componentWillMount() {
        this.timeouts = [];
    }

    componentWillUnmount() {
        this.timeouts.forEach(window.clearTimeout);
    }


    render() {

        const {classes} = this.props;

        return (
            <React.Fragment>
                <NavBar/>

                <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                >
                    <Grid item>
                        <Avatar className={classes.avatar} component="div">M</Avatar>
                    </Grid>
                    <Grid item>
                        <Typography variant="h5">Marcel Mittenb&uuml;hler</Typography>
                    </Grid>
                    <Grid item>
                        <TitleTypist/>
                    </Grid>
                    <Grid item className={classes.social}>
                        <SocialLinks/>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }
}

export default withStyles(style)(PersonOverview)